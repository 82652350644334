






import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalPretestInformation extends Mixins(ModalBaseMethod) {
  private name = ''

  public async showModal(id: number, userId: number) {
    await Vue.prototype.$http.httpWithToken
      .get(`/history/entranceResult/testname`, { params: { id: id, userId: userId } })
      .then((res: any) => {
        this.name = `${res.data.testname}（${res.data.count}回目）`
        this.show()
      })
      .catch(() => {
        alert('エラーが発生しました')
      })
  }
}
