





















import { Component, Vue, Mixins, Prop, Ref } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import PretestInformation from '@/components/organisms/PretestInformation.vue'
import StudentDashboardUnderstanding from '@/components/organisms/StudentDashboardUnderstanding.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ModalPretestInformation from '@/components/organisms/ModalPretestInformation.vue'

@Component({
  components: {
    ColoredBox,
    PretestInformation,
    StudentDashboardUnderstanding,
    ModalPretestInformation,
  },
})
export default class NYContents extends Mixins(ClassModeMethods) {
  @Prop()
  understandingTotal!: {}

  @Prop()
  pretestResults!: object[]

  @Prop()
  userId!: number

  @Ref() modalPretestInformation!: ModalPretestInformation

  private get studentName(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.userNickname || ''
  }

  private showModal(id: number): void {
    this.modalPretestInformation.showModal(id, this.userId)
  }
}
