

























import { Component, Mixins, Prop } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RatioBar from '@/components/atoms/RatioBar.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SubjectAchievement from '@/components/organisms/SubjectAchievement.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import LocalMoment from '../atoms/LocalMoment.vue'

@Component({
  components: {
    ButtonBase,
    RatioBar,
    ColoredBox,
    SubjectAchievement,
  },
})
export default class StudentAchievement extends Mixins(LocalMoment) {
  @Prop({ default: null })
  goal?: StudentGoal

  @Prop({ default: false })
  isTeacher?: boolean

  private get goalPeriod() {
    if (!this.goal) return ''

    const rfFrom = this.reformatDate(this.goal.from, 'YYYY-MM-DD', 'YYYY年MM月DD日')
    const rfTo = this.reformatDate(this.goal.to, 'YYYY-MM-DD', 'YYYY年MM月DD日')
    return `${rfFrom}~${rfTo}`
  }
}
