
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Line, mixins } from 'vue-chartjs'

export type ChartRecord = {
  date: string
  level?: number
}

@Component
export default class ChartLineUnderstanding extends Mixins(Line, mixins.reactiveProp) {
  @Prop()
  id?: string

  @Prop()
  records!: ChartRecord[]

  @Prop()
  dateSteps?: string[]

  get chartId(): string {
    return this.id || `id${this.$uuid.v4()}`
  }

  private labelDates(): string[] {
    return (
      this.dateSteps ||
      this.records.map((item) => {
        return item.date
      })
    )
  }

  private data: Chart.ChartData = {}

  private options: Chart.ChartOptions = {}

  private setOptions() {
    const labelDates = this.labelDates()
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      spanGaps: true,
      elements: {
        line: {
          tension: 0,
          borderColor: '#3B9DFF',
          borderWidth: 3,
        },
        point: {
          radius: 7,
          borderWidth: 3,
          borderColor: '#FFFFFF',
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                if (labelDates.includes(value.toString())) return value
              },
              maxTicksLimit: 365,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
              max: 100,
            },
          },
        ],
      },
    }
  }

  private setChartData() {
    this.data = {
      labels: this.records.map(function (item) {
        return item.date
      }),
      datasets: [
        {
          data: this.records.map(function (item) {
            return item.level
          }),
          backgroundColor: 'rgba(119, 187, 255, 0.25)',
          pointBackgroundColor: '#3B9DFF',
        },
      ],
    }
  }

  private mounted(): void {
    this.setOptions()
    this.setChartData()
    this.renderChart(this.data, this.options)
  }

  @Watch('records')
  private onSetRecords() {
    this.setOptions()
    this.setChartData()
    this.renderChart(this.data, this.options)
  }
}
