






























import { Component, Prop, Vue } from 'vue-property-decorator'
import StudentHistoryContent from '@/components/organisms/StudentHistoryContent.vue'
import ChartLineUnderstandingRelative from '@/components/atoms/ChartLineUnderstandingRelative.vue'

@Component({
  components: {
    StudentHistoryContent,
    ChartLineUnderstandingRelative,
  },
})
export default class StudentTestHistorySubject extends Vue {
  private selectedDate: Date | null = null

  private tabBaseDatas: object[] = [
    { id: 1, name: '中1' },
    { id: 2, name: '中2' },
    { id: 3, name: '中3' },
  ]

  private isActiveTab = 1

  private understandingRecords: object[] = [
    { date: '8/1', level: 70 },
    { date: '8/2' },
    { date: '8/3', level: 80 },
    { date: '8/4', level: 60 },
    { date: '8/5', level: 90 },
    { date: '8/6', level: 80 },
  ]

  @Prop()
  status!: {}

  @Prop()
  subject!: {}
}
