import { StudentGoal } from '@/models/api/studentGoal'
import { Component, Mixins, Vue } from 'vue-property-decorator'
import LearningType, { LearningTypePage } from '../historyv3/LearningType'

@Component
export default class StudentGoalApi extends Mixins(LearningType) {
  /**
   * 現在設定中の目標取得
   *
   * @classMode クラスモード
   * @userId ユーザID 指定なしの場合、ログイン中のユーザの情報を取得する
   */
  protected async loadCurrentGoal(classMode: string, userId: number | null = null) {
    const params = {}
    if (userId) params['userId'] = userId
    if (!this.callApiV3) params['classModeCode'] = classMode
    const { data } = await Vue.prototype.$http.httpWithToken.get(
      `${this.callApiV3 ? '/v3' : ''}/studentGoals/current`,
      {
        params: params,
      }
    )
    return data
  }

  /**
   * 過去の目標一覧取得
   *
   * @classMode クラスモード
   * @userId ユーザID 指定なしの場合、ログイン中のユーザの情報を取得する
   */
  protected async loadPrevGoals(classMode: string, userId: number | null = null) {
    const params = { isWithoutCurrent: true, userId: userId }
    if (this.callApiV3) {
      params['is_other_users '] = false
    } else {
      params['classModeCode'] = classMode
    }

    const { data }: { data: StudentGoal[] } = await Vue.prototype.$http.httpWithToken.get(
      `${this.callApiV3 ? '/v3' : ''}/student_goals`,
      {
        params,
      }
    )
    // 現在の目標取得
    return data
  }
}
