












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RatioBar extends Vue {
  @Prop({ default: 0 })
  ratio?: number

  @Prop()
  fillColor?: string

  @Prop()
  barColor?: string

  get setStyle(): { [key: string]: string } {
    return {
      '--ratio': `${this.ratio}%`,
      '--fill-color': this.fillColor ? this.fillColor : 'var(--color-gray-6)',
      '--background': this.barColor ? this.barColor : 'var(--fill-color)',
    }
  }
}
