














import { Component, Prop, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { EntranceResult } from '@/models/api/history/entrance.ts'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class PretestInformation extends Mixins(StyleSubjectBackground) {
  @Prop()
  unit!: EntranceResult

  @Prop({ default: false })
  showButton?: boolean

  @Prop({ default: false })
  isTeacher?: boolean

  get setStyle(): { [key: string]: string } {
    if (this.isTeacher) {
      return {
        '--subject-bg-color': 'var(--color-gray-4)',
        '--subject-bg-image': 'none',
        '--title-color': 'var(--color-gray-1)',
      }
    } else {
      const subjectStyle: { [key: string]: string } = this.getSubjectStyle(this.unit.subjectCode)
      subjectStyle['--title-color'] = 'var(--color-white)'
      return subjectStyle
    }
  }

  get setProps(): {} {
    const props: { [key: string]: string } = {}
    if (this.isTeacher) {
      props.shadow = 'none'
    }
    return props
  }

  private resultUrl(): string {
    const resultId = this.unit.result.resultId
    return this.isTeacher
      ? `/teacher/history/drillResult/${resultId}?prevPage=${this.$route.name}`
      : `/student/history/drillResult/${resultId}?prevPage=${this.$route.name}`
  }

  private showModal(): void {
    this.$emit('show-modal', this.unit.result.resultId)
  }
}
