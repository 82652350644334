






















import { Component, Prop, Mixins } from 'vue-property-decorator'
import RatioBar from '@/components/atoms/RatioBar.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'

@Component({
  components: {
    RatioBar,
    ButtonBase,
  },
})
export default class SubjectAchievement extends Mixins(StyleSubjectBackground) {
  @Prop()
  subject!: {
    subjectName: string
    subjectCode: string
    score: number
    unit: string
    resultScore: number
    resultRate: number
  }

  @Prop({ default: false })
  isTeacher?: boolean

  get styleSet(): any {
    return function (code: string): { [key: string]: string } {
      return { '--background-image': 'url(/img/btn_kyouka/btn_kyouka_' + code + '.png)' }
    }
  }

  get barStyleSet(): any {
    const _: any = this
    return function (code: string): { [key: string]: string } {
      let fillColor = null
      let lineColor = null

      if (_.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = _.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
        console.log(subjectStyle)
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }
}
