














import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentDashboardUnderstanding from '@/components/organisms/StudentDashboardUnderstanding.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    ColoredBox,
    StudentDashboardUnderstanding,
    ButtonBase,
  },
})
export default class KSContents extends Mixins(ClassModeMethods) {
  @Prop()
  seasonSettingPeriod!: {}

  @Prop()
  understandingTotal!: {}

  private get studentName(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.userNickname || ''
  }
}
