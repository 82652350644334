




import { Component, Vue } from 'vue-property-decorator'
import ChartLineUnderstanding, { ChartRecord } from '@/components/atoms/ChartLineUnderstanding.vue'

export { ChartRecord }
@Component({
  inheritAttrs: false,
  components: {
    ChartLineUnderstanding,
  },
})
export default class ChartLineUnderstandingRelative extends Vue {
  get relativeStyle() {
    return {
      height: '100%',
      width: '100%',
      position: 'relative',
    }
  }
}
