





















import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'

@Component({
  components: {
    ColoredBox,
  },
})
export default class NotificationList extends Vue {
  private showAll = false

  private defaultShowNotifications = 3

  @Prop()
  items!: []

  @Prop()
  radius!: string

  @Prop()
  linkClass!: string

  get setStyle(): { [key: string]: string } {
    const linkColor = this.linkClass === 'student' ? '--color-blue-1' : '--color-blue'
    return {
      '--link-color': `var(${linkColor})`,
    }
  }

  private isShown(index: number): boolean {
    return this.showAll || index < this.defaultShowNotifications
  }

  private expand(): void {
    this.showAll = true
  }

  private collapse(): void {
    this.showAll = false
  }
}
