




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AccordionBase extends Vue {
  @Prop({ default: false })
  opened!: boolean

  private isOpened = this.opened

  private colortype = ''

  get setColor(): { [key: string]: string } {
    if (this.colortype === 'lightblue') {
      return {
        '--title-color': 'rgba(44,121,206,0.2)',
        '--icon-color': 'var(--color-blue)',
      }
    }
    return {}
  }

  private accordionToggle(): void {
    this.isOpened = !this.isOpened
  }

  private beforeEnter(el: HTMLElement): void {
    el.style.height = '0'
  }

  private enter(el: HTMLElement): void {
    el.style.height = `${el.scrollHeight}px`
  }

  private beforeLeave(el: HTMLElement): void {
    el.style.height = `${el.scrollHeight}px`
  }

  private leave(el: HTMLElement): void {
    el.style.height = '0'
  }

  private afterEnter(): void {
    this.$emit('after-opend')
  }
}
