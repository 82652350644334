
















































import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SubjectProgress from '@/components/organisms/SubjectProgress.vue'
import AccordionBase from '@/components/atoms/AccordionBase.vue'
import StudentTestHistorySubject from '@/components/organisms/StudentTestHistorySubject.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { TermInformationType } from '@/models/api/resultProgresses'
import { SubjectProgressType } from '@/mixins/utils/StepProgressMethods'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import { StudentGoal } from '@/models/api/studentGoal'

@Component({
  components: {
    ColoredBox,
    SubjectProgress,
    AccordionBase,
    StudentTestHistorySubject,
    ButtonBase,
  },
})
export default class TKContents extends Mixins(ClassModeMethods) {
  private get studentName(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.userNickname || ''
  }

  private subjectlink(key: string): void {
    location.hash = key
  }

  // 現在の目標
  @Prop()
  currentGoal!: StudentGoal

  @Prop()
  subjectSteps!: SubjectProgressType[]

  @Prop()
  termInformation!: TermInformationType

  // 定期テスト目標点数
  private get currentTargetScores() {
    return this.currentGoal?.studentGoalTermExams || []
  }

  private get termName(): string {
    return this.termInformation?.termName || ''
  }

  private get termGrade(): string {
    return this.termInformation?.gradeName || ''
  }
}
