














import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StudentDashboardUnderstanding from '@/components/organisms/StudentDashboardUnderstanding.vue'
import StudentAchievement from '@/components/organisms/StudentAchievement.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    StudentDashboardUnderstanding,
    StudentAchievement,
  },
})
export default class TJContents extends Mixins(ClassModeMethods) {
  @Prop()
  currentGoal!: StudentGoal

  @Prop()
  understandingTotal!: {}

  @Prop()
  understandingHistories!: object[]

  private get studentName(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.userNickname || ''
  }
}
