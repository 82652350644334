






import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalNotification extends Mixins(ModalBaseMethod) {
  private title = ''
  private message = ''

  public async loadNotification(id: number) {
    await Vue.prototype.$http.httpWithToken
      .get(`/notifications/${id}`)
      .then((res: any) => {
        this.title = res.data.title
        this.message = res.data.message
        this.show()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
