
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
interface WeekCode {
  weekCodeToText(weekCode: string): string
  weekCodesToTexts(weekCodes: string[]): string[]
  weekCodeList(): { code: string; text: string }[]
}
@Component
export default class WeekCodeMethods extends Vue {
  private WEEK_CODE_TEXT = {
    MON: '月',
    TUE: '火',
    WED: '水',
    THU: '木',
    FRI: '金',
    SAT: '土',
    SUN: '日',
  }
  private WEEK_CODE_ORDER = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

  /**
   * 曜日コードから表示用文字列を取得
   * @param weekCode 曜日の3レターコード
   * @return 表示用の曜日文字列
   */
  protected weekCodeToText(weekCode: string): string {
    return this.WEEK_CODE_TEXT[weekCode]
  }

  /**
   * 曜日コードから表示用文字列を配列で取得
   * @param weekCode 曜日の3レターコード配列
   * @return 表示用の曜日文字列の配列
   */
  protected weekCodesToTexts(weekCodes: string[]): string[] {
    return weekCodes.map((weekCode) => {
      return this.weekCodeToText(weekCode)
    })
  }

  /**
   * 曜日コードと表示用文字列を配列で取得
   * @return 曜日コードと曜日文字列のオブジェクト配列
   */
  protected weekCodeList(): { code: string; text: string }[] {
    return this.WEEK_CODE_ORDER.map((code) => {
      return { code: code, text: this.WEEK_CODE_TEXT[code] }
    })
  }
}
