



































import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import ChartDonutSubject from '@/components/molecules/ChartDonutSubject.vue'
import ChartFillPattern from '@/components/atoms/ChartFillPattern.vue'
import StudentHistoryContent from '@/components/organisms/StudentHistoryContent.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    ChartDonutSubject,
    StudentHistoryContent,
  },
})
export default class StudentDashboardUnderstanding extends Mixins(ChartFillPattern, ClassModeMethods) {
  @Prop()
  total!: {}

  @Prop({ default: [] })
  histories?: object[]

  @Prop()
  period?: { startAt: string; endAt: string; text: string }

  get styleSet(): any {
    const _: any = this
    return function (key: string) {
      return {
        '--background-image': 'url(' + _.getPattern(key).src + ')',
        '--background-size': _.isTeacher ? '50%' : 'cover',
      }
    }
  }
}
