








import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'

@Component({
  components: {
    ColoredBox,
  },
})
export default class HomeworkList extends Vue {
  @Prop()
  items!: []

  private async selectSubject(subjectCode: string): Promise<any> {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
